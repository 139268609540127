<template>
  <div class="addPlayer">
    <div class="siteBox">
      <div>场地：</div>
      <div
        class="groupBox"
        v-if="siteRefereeList && siteRefereeList.length > 0"
      >
        <div
          :class="index == isactive ? 'active' : ''"
          @click="handelchange(index, item)"
          v-for="(item, index) in siteRefereeList"
          :key="index"
        >
          {{ item.city }} — {{ item.siteName }}
        </div>
      </div>
      <div class="groupBox groupBox1" v-else>暂无数据</div>
    </div>
    <div class="choiceBox">
      <div class="leftBox">
        <el-transfer
          filterable
          :filter-method="filterMethod"
          :titles="['全部选手', '已选选手']"
          filter-placeholder="请输入选手名称"
          v-model="formData.playerIdList"
          :data="playerList"
          :render-content="renderFunc"
          :props="{
            key: 'id',
            label: 'name',
          }"
        >
        </el-transfer>
        <div class="btn">
          <el-button @click="cancel">取消</el-button>
          <el-button @click="sendRoleList" :loading="loading1">确定</el-button>
        </div>
      </div>
      <div class="rightBox">
        <el-table
          v-loading="loading"
          :data="comList"
          border
          style="width: 100%"
          height="400"
          size="small"
        >
          <el-table-column prop="number" label="选手编号" width="70">
          </el-table-column>
          <el-table-column prop="name" label="选手姓名" width="70">
          </el-table-column>
          <el-table-column label="性别" width="50">
            <template slot-scope="scope">
              <span v-if="scope.row.sex === true">男</span>
              <span v-else-if="scope.row.sex === false">女</span>
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" width="50">
          </el-table-column>
          <el-table-column prop="idCard" label="身份证号"> </el-table-column>
          <el-table-column prop="telephone" label="联系方式"> </el-table-column>
          <el-table-column prop="city" label="城市"> </el-table-column>
          <el-table-column prop="company" label="单位"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { addCompetitionPlayer, getSelectAllPlayer, getSelectCompetitionPlayer, getSelectAllPlayerByComId, getCompetitionSite } from '@/api/eventManagement/competitionProject/index.js'
export default {
  name: 'AddPlayer',
  inject: ['reload'],
  components: {},
  props: {},
  data() {
    return {
      formData: {
        competitionId: parseInt(sessionStorage.getItem('competitionId')) ? parseInt(sessionStorage.getItem('competitionId')) : null,
        playerIdList: [], // 选手idList
        relationId: null // 	比赛场地id
      },
      isactive: 0,
      siteRefereeList: [],
      loading: false,
      loading1: false,
      playerList: [],
      comPlayerList: [],
      comList: [],
      renderFunc(h, option) {
        return <span>{option.name} — {option.city}</span>;
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getCompetitionSite();
  },
  methods: {
    // 穿梭框搜索方法
    filterMethod(query, item) {
      // console.log(query, item);
      return item.name.indexOf(query) > -1 || item.city.indexOf(query) > -1;
    },
    // 根据比赛查询场地列表
    async getCompetitionSite() {
      const res = await getCompetitionSite(this.formData.competitionId);
      // console.log(res);
      if (res.status === 200) {
        this.siteRefereeList = res.data;
        if (res.data && res.data.length > 0) {
          this.formData.relationId = res.data[0].id;
          this.getSelectCompetitionPlayer();
          this.getSelectAllPlayerByComId();
          this.getSelectAllPlayer(res.data[0].city);
        }
      }
    },
    // 场地事件
    handelchange(index, item) {
      // console.log(item);
      this.isactive = index;
      this.formData.playerIdList = [];
      this.formData.relationId = item.id;
      this.getSelectCompetitionPlayer();
      this.getSelectAllPlayerByComId();
      this.getSelectAllPlayer(item.city);
    },
    // 获取选手列表
    async getSelectAllPlayer(val) {
      const res = await getSelectAllPlayer({city: val ? val : ''});
      // console.log(res);
      if (res.status === 200) {
        this.playerList = res.data;
      }
    },
    // 获取列表
    async getSelectAllPlayerByComId() {
      const res = await getSelectAllPlayerByComId({ relationId: this.formData.relationId });
      // console.log(res);
      this.loading = true;

      if (res.status === 200) {
        this.comList = res.data;
        this.loading = false;
      }
    },
    // 获取已选选手
    async getSelectCompetitionPlayer() {
      // const res = await getSelectCompetitionPlayer({ competitionId: this.formData.competitionId });
      const res = await getSelectCompetitionPlayer({ relationId: this.formData.relationId });
      // console.log(res);
      if (res.status === 200) {
        this.comPlayerList = res.data;
        this.comPlayerList.forEach(item => {
          this.formData.playerIdList.push(item.playerId);
        })
        // console.log(this.comPlayerList);
      }
    },
    // 选人弹层确定按钮
    async sendRoleList() {

      if (sessionStorage.getItem('competitionId') === null) {
        this.$message({
          type: 'warning',
          message: '请先完善新建项目板块信息！',
          showClose: true
        })
        return
      }
      if (this.formData.playerIdList.length === 0) {
        return this.$message({
          type: 'warning',
          message: '请添加选手！',
          showClose: true
        })
      }
      this.loading1 = true;

      // console.log(this.formData);
      // console.log(this.val);
      const res = await addCompetitionPlayer(this.formData)
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '新增成功!'
        });
        this.loading1 = false;
        // sessionStorage.setItem('currentView', 'grouping');
        // sessionStorage.setItem('isactive', 3);
        this.reload(); // 需要刷新页面
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '新增失败!' + res.msg
        });
        this.loading1 = false;
      }
    },
    //  选人弹层取消按钮
    cancel() {
      this.$router.push('/home/competitionProject');
    },
  }
}
</script>

<style lang="scss" scoped>
.addPlayer {
  .siteBox {
    // border-bottom: 1px solid #ccc;
    .groupBox {
      height: 30px;
      display: flex;
      margin: 20px 0;
      div {
        cursor: pointer;
        min-width: 75px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // border: 1px solid #ccc;
        border-radius: 8px;
        overflow: hidden;
        color: #303030;
        background: #fff;
        margin-right: 50px;
        padding: 0 10px;
      }
      .active {
        background: #06206b;
        color: #ffffff;
      }
    }
    .groupBox1 {
      color: #aaa;
    }
  }
  .choiceBox {
    display: flex;
    width: 100%;
    margin-top: 30px;
    .leftBox {
      width: 50%;
      margin-right: 2%;
      border-radius: 6px;
      border: 1px solid #ddd;
      box-sizing: border-box;
      padding: 15px;
      padding-bottom: 5px;
      .btn {
        text-align: center;
        margin-top: 36px;
        padding-bottom: 10px;
        button {
          cursor: pointer;
          // width: 75px;
          // height: 30px;
          // line-height: 30px;
          background: #e8f8fb;
          border-radius: 4px;
          border: none;
          margin: 0px 32px;
          background: #eaefff;
          color: #06206b;
          &:nth-child(2) {
            background: #06206b;
            color: white;
          }
        }
      }
    }
    .rightBox {
      width: 48%;
    }
  }
  ::v-deep .el-transfer-panel {
    width: 42%;
    height: 450px;
    .el-transfer-panel__body {
      height: 410px;
    }
    .el-transfer-panel__list {
      height: 350px;
    }
  }
  ::v-deep .el-transfer__buttons {
    width: calc(16% - 60px);
    .el-button {
      margin: 0;
      &:nth-child(1) {
        margin-bottom: 20px;
      }
    }
  } //滚动条的宽度
  .your-table .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  //滚动条的滑块
  .your-table .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
  }
  // 如果是整个页面的滚动条风格是一致的，直接改全局的滚动条样式也可以有效果
  //滚动条的宽度
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  //滚动条的滑块
  ::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 3px;
  }
}
</style>
